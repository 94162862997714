'format es6';
'use strict';

import $ from 'jquery';

const $window = $(window);

export default {
	init() {
		const mainMenu = $('[data-main-menu]');
		const menuBtn = $('[data-menu-btn]', mainMenu);

		$window.on('resize', () => {
			if (window.matchMedia('screen and (max-width: 767px)').matches) {
				menuBtn.off('click').on('click', (e) => {
					e.preventDefault();
					mainMenu.toggleClass('opened');
				});
			} else {
				menuBtn.off('click');
			}
		}).resize();
	},
};
