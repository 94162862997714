'format es6';
'use strict';

import $ from 'jquery';
import { docReady } from './utils/docReady';
import selectric from 'selectric';
import { scrollToElem } from './utils/scrollTo';
import Menu from './Menu';

docReady.then(() => {
	selectric();

	$('select').selectric({ disabledOnMobile: false });
	Menu.init();

	$('[href^="#"]').on('click', (e) => {
		e.preventDefault();

		const targetEl = $($(e.currentTarget).attr('href'));
		if (targetEl.length > 0) {
			scrollToElem(targetEl);
		}
	});

	$('[href*="#contact"]').on('click', (e) => {
		if ($('body').hasClass('home')) {
			e.preventDefault();
			e.stopPropagation();

			const targetEl = $('#contact');
			if (targetEl.length > 0) {
				scrollToElem(targetEl);
			}
		}
	});

	$('input[type=file]').on('change', (e) => {
		const val = $(e.currentTarget).val();
		console.log(val);
		$(e.currentTarget).parent().attr('data-value', val);
	});

	const $form = $('[data-contact-form]');
	$form.on('submit', (e) => {
		e.preventDefault();
		const f = $(e.currentTarget);

		f.parent().height(f.parent().height());

		f.fadeOut(300, () => {
			$.ajax({
				url: f.attr('action'),
				method: f.attr('method'),
				data: f.serialize(),
				dataType: 'JSON',
				success(data) {
					if (data.success) {
						f.parent().find('[data-success-message]').fadeIn();
					} else {
						f.parent().find('[data-error-message]').fadeIn();
						f.fadeIn();

						f.parent().height('auto');
					}
				},
				error() {
					f.parent().find('[data-error-message]').fadeIn();
					f.fadeIn();

					f.parent().height('auto');
				},
			});
		});
	});
});
